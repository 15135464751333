import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';

export const ANGULAR_MODULE = [
  MatCheckboxModule,
  MatInputModule,
  MatListModule,
  MatRadioModule,
  MatSelectModule,
  MatTabsModule,
  ReactiveFormsModule,
];
