// Angular
import { NgModule } from '@angular/core';
import { ANGULAR_MODULE } from './anguler-modules';
// Primeng
import { PRIME_NG_MODULE } from './primeng-modules';
// Ngx
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
// Caloudi
import { CUSTOM_COMPONENTS, D3_CHARTS_COMPONENTS, PRIME_NG_COMPONENTS } from '@base-shared';
import { CUSTOM_DIRECTIVES } from '@base/directive';
import { CUSTOM_PIPES } from '@base/pipe';
import { GoogleChartService } from '@base/service';
// Third Party
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { PowerBIEmbedModule } from 'powerbi-client-angular';

const CUSTOM_MODULE = [
  TranslateModule,
  FontAwesomeModule,
  PowerBIEmbedModule,
  NgxSpinnerModule,
  NgxPageScrollModule,
  NgxPageScrollCoreModule.forRoot(),
];

const CUSTOM_SERVICE = [GoogleChartService, NgxSpinnerService];

@NgModule({
  imports: [
    PRIME_NG_MODULE,
    ANGULAR_MODULE,
    CUSTOM_MODULE
  ],
  declarations: [
    PRIME_NG_COMPONENTS,
    D3_CHARTS_COMPONENTS,
    CUSTOM_COMPONENTS,
    CUSTOM_DIRECTIVES,
    CUSTOM_PIPES
  ],
  providers: [
    CUSTOM_SERVICE,
    CUSTOM_DIRECTIVES,
    CUSTOM_PIPES
  ],
  exports: [
    PRIME_NG_MODULE,
    ANGULAR_MODULE,
    CUSTOM_MODULE,
    PRIME_NG_COMPONENTS,
    D3_CHARTS_COMPONENTS,
    CUSTOM_COMPONENTS,
    CUSTOM_DIRECTIVES,
    CUSTOM_PIPES,
  ],
})
export class PrimengModule { }
