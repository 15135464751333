import * as i0 from '@angular/core';
import { EventEmitter, Directive, Optional, Inject, Input, Output, NgModule } from '@angular/core';
import * as i1 from 'ngx-page-scroll-core';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import * as i2 from '@angular/router';
import { NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { filter, take } from 'rxjs/operators';

/* eslint-disable @angular-eslint/no-host-metadata-property, @angular-eslint/directive-selector */
class NgxPageScrollDirective {
  constructor(pageScrollService, router, document) {
    this.pageScrollService = pageScrollService;
    this.router = router;
    this.pageScrollAdjustHash = false;
    this.pageScrollFinish = new EventEmitter();
    this.document = document;
  }
  ngOnChanges(changes) {
    // Some inputs changed, reset the pageScrollInstance
    this.pageScrollInstance = undefined;
  }
  ngOnDestroy() {
    if (this.pageScrollInstance) {
      this.pageScrollService.stop(this.pageScrollInstance);
    }
  }
  getPageScrollTarget() {
    return this.pageScrollTarget || this.href || (this.fragment ? '#' + this.fragment : '');
  }
  generatePageScrollInstance() {
    if (this.pageScrollInstance === undefined || this.pageScrollInstance === null) {
      const options = {
        document: this.document,
        scrollTarget: this.getPageScrollTarget()
      };
      if (this.pageScroll) {
        options.namespace = this.pageScroll;
      }
      if (this.pageScrollHorizontal !== undefined && this.pageScrollHorizontal !== null) {
        options.verticalScrolling = !this.pageScrollHorizontal;
      }
      if (this.pageScrollOffset !== undefined && this.pageScrollOffset !== null) {
        options.scrollOffset = this.pageScrollOffset;
      }
      if (this.pageScrollInterruptible !== undefined && this.pageScrollInterruptible !== null) {
        options.interruptible = this.pageScrollInterruptible;
      }
      if (this.pageScrollInView !== undefined && this.pageScrollInView !== null) {
        options.scrollInView = this.pageScrollInView;
      }
      if (this.pageScrollEasing) {
        options.easingLogic = this.pageScrollEasing;
      }
      if (this.pageScrollDuration !== undefined && this.pageScrollDuration !== null) {
        options.duration = this.pageScrollDuration;
      }
      if (this.pageScrollSpeed !== undefined && this.pageScrollSpeed !== null) {
        options.speed = this.pageScrollSpeed;
      }
      if (this.pageScrollFinish) {
        options.scrollFinishListener = this.pageScrollFinish;
      }
      this.pageScrollInstance = this.pageScrollService.create(options);
    }
    return this.pageScrollInstance;
  }
  pushRouterState() {
    if (this.pageScrollAdjustHash && typeof this.pageScrollInstance.pageScrollOptions.scrollTarget === 'string' && this.pageScrollInstance.pageScrollOptions.scrollTarget.substr(0, 1) === '#') {
      // "Navigate" to the current route again and this time set the fragment/hash
      this.router.navigate([], {
        fragment: this.pageScrollInstance.pageScrollOptions.scrollTarget.substr(1),
        queryParamsHandling: 'preserve'
      });
    }
  }
  scroll() {
    const pageScrollInstance = this.generatePageScrollInstance();
    this.pushRouterState();
    this.pageScrollService.start(pageScrollInstance);
  }
  handleClick(clickEvent) {
    if (this.routerLink && this.router !== null && this.router !== undefined) {
      let urlTree;
      if (typeof this.routerLink === 'string') {
        urlTree = this.router.parseUrl(this.routerLink);
      } else {
        urlTree = this.router.createUrlTree(this.routerLink);
      }
      if (!this.router.isActive(urlTree, true)) {
        // We need to navigate their first.
        // Navigation is handled by the routerLink directive so we only need to listen for route change
        this.router.events.pipe(filter(routerEvent => {
          // We're only interested in successful navigations or when the navigation fails
          return routerEvent instanceof NavigationEnd || routerEvent instanceof NavigationError || routerEvent instanceof NavigationCancel;
        }),
        // Consume only one event, automatically "unsubscribing" from the event stream afterwards
        take(1)).subscribe(routerEvent => {
          if (routerEvent instanceof NavigationEnd) {
            // use a timeout to start scrolling as soon as the stack is cleared
            setTimeout(() => {
              this.scroll();
            }, 0);
          }
        });
        return false; // to preventDefault()
      }
    }
    this.scroll();
    return false; // to preventDefault()
  }
  static {
    this.ɵfac = function NgxPageScrollDirective_Factory(t) {
      return new (t || NgxPageScrollDirective)(i0.ɵɵdirectiveInject(i1.PageScrollService), i0.ɵɵdirectiveInject(i2.Router, 8), i0.ɵɵdirectiveInject(DOCUMENT));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgxPageScrollDirective,
      selectors: [["", "pageScroll", ""]],
      hostBindings: function NgxPageScrollDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function NgxPageScrollDirective_click_HostBindingHandler($event) {
            return ctx.handleClick($event);
          });
        }
      },
      inputs: {
        routerLink: "routerLink",
        href: "href",
        fragment: "fragment",
        pageScrollTarget: "pageScrollTarget",
        pageScrollHorizontal: "pageScrollHorizontal",
        pageScrollOffset: "pageScrollOffset",
        pageScrollDuration: "pageScrollDuration",
        pageScrollSpeed: "pageScrollSpeed",
        pageScrollEasing: "pageScrollEasing",
        pageScrollInterruptible: "pageScrollInterruptible",
        pageScrollInView: "pageScrollInView",
        pageScrollAdjustHash: "pageScrollAdjustHash",
        pageScroll: "pageScroll"
      },
      outputs: {
        pageScrollFinish: "pageScrollFinish"
      },
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPageScrollDirective, [{
    type: Directive,
    args: [{
      selector: '[pageScroll]',
      host: {
        '(click)': 'handleClick($event)'
      }
    }]
  }], () => [{
    type: i1.PageScrollService
  }, {
    type: i2.Router,
    decorators: [{
      type: Optional
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }], {
    routerLink: [{
      type: Input
    }],
    href: [{
      type: Input
    }],
    fragment: [{
      type: Input
    }],
    pageScrollTarget: [{
      type: Input
    }],
    pageScrollHorizontal: [{
      type: Input
    }],
    pageScrollOffset: [{
      type: Input
    }],
    pageScrollDuration: [{
      type: Input
    }],
    pageScrollSpeed: [{
      type: Input
    }],
    pageScrollEasing: [{
      type: Input
    }],
    pageScrollInterruptible: [{
      type: Input
    }],
    pageScrollInView: [{
      type: Input
    }],
    pageScrollAdjustHash: [{
      type: Input
    }],
    pageScroll: [{
      type: Input
    }],
    pageScrollFinish: [{
      type: Output
    }]
  });
})();
class NgxPageScrollModule {
  static {
    this.ɵfac = function NgxPageScrollModule_Factory(t) {
      return new (t || NgxPageScrollModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxPageScrollModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [NgxPageScrollCoreModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPageScrollModule, [{
    type: NgModule,
    args: [{
      declarations: [NgxPageScrollDirective],
      imports: [NgxPageScrollCoreModule],
      exports: [NgxPageScrollDirective]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-page-scroll
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxPageScrollDirective, NgxPageScrollModule };
